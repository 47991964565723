<template>
  <div id="instances">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Header />
      <Alert
        :showAlert="isErrorMessage"
        v-if="isErrorMessage"
        type="error"
        class="mt-2"
        allowDismiss
        :text="errorMessage"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
    <AdsDataTable
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': [20, 30, 50, -1],
      }"
      :items-per-page="20"
      search-label="Find instances"
      sort-desc
      @click:row="openInstance"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
          color="green"
          textColor="white"
          v-if="item.status === 'Active'"
        >
          {{ item.status }}
        </v-chip>
        <v-chip
          color="orange"
          v-if="item.status === 'Deprecated'"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <AdsButton
          :primary="true"
          v-if="item.clientId == null && item.status === 'Active' && !isReadOnlyUser"
          buttonText="Generate Client ID"
          class="mr-4 generate-client-id-btn"
          @click="generateClientId(item)"
        />
      </template>
    </AdsDataTable>
  </div>
</template>
<script>
import { AdsDataTable, AdsButton, Alert } from '@nswdoe/doe-ui-core'
import Header from '@/views/Instances/Header/Header'

export default {
  name: 'InstancesListing',
  title: 'Instances - SAIS Operation UI Portal',
  components: {
    AdsDataTable,
    AdsButton,
    Header,
    Alert,
  },
  data() {
    return {
      headers: [
        { text: 'Supplier', value: 'supplierName' },
        { text: 'Status', value: 'status' },
        { text: 'Client ID', value: 'clientId' },
        { text: 'Actions', value: 'actions' },
      ],
      items: [],
      itemsPerPageOptions: [
        { value: 20, title: '20' },
        { value: 30, title: '30' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
      ],
    }
  },
  created() {
    this.$store.dispatch('moduleInstances/fetchInstances').finally(() => {
      this.items = this.$store.state.moduleInstances.instances
    })
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    errorMessage() {
      return this.$store.state.moduleInstances.errorMessage
    },
    isErrorMessage() {
      const errorMessage = this.$store.state.moduleInstances.errorMessage
      return !!errorMessage && errorMessage.length > 0
    },
  },
  methods: {
    openInstance(item, table, event) {
      if (
        event.target.classList.contains('generate-client-id-btn') ||
        event.target.closest('.generate-client-id-btn')
      ) {
        console.log('has generate client id')
        return
      }

      this.$router.push(`/serviceregistry/instances/view/${item.supplierId}`)
    },
    async generateClientId(item) {
      const supplierType = item.supplierType === 'AMPS' ? 'ampsvendor' : 'nonampsvendor'
      const accountId = `${supplierType}-${item.supplierCode}`.toLowerCase()
      const payload = {
        supplierId: item.supplierId,
        accountId: accountId,
      }
      await this.$store.dispatch('moduleInstances/postInstance', payload)
      this.items = this.$store.state.moduleInstances.instances
    },
  },
}
</script>
